<template>
  <div class='page-box'>
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="queryRef" @submit="handleSearch" @submit.native.prevent>
        <a-form-model-item label="姓名" prop="name">
          <a-input v-model="queryRef.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="会员编号" prop="userNo">
          <a-input v-model="queryRef.userNo" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="queryRef.phone" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="等级" prop="levelId">
          <a-select style="width: 120px" placeholder="请选择" v-model='queryRef.levelId'>
            <a-select-option :value="item.levelId" :key='item.levelId + index' v-for='(item,index) in levelList'>
              {{item.levelName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="上级" prop="upAgentName">
          <a-input v-model="queryRef.upAgentName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="加入时间" prop="dateRange">
          <a-range-picker v-model='queryRef.dateRange' format="YYYY-MM-DD" value-format="YYYY-MM-DD" />
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class='info-box'>
      <div class='info-item' v-for='(item,index) in infoData' :key='index'>{{item.name}}：{{item.value}}</div>
    </div>
    <a-tabs v-model='queryRef.type' @change="changeTab">
      <a-tab-pane :key="0" tab="我的团队">
      </a-tab-pane>
      <!-- <a-tab-pane :key="1" tab="我的客户">
      </a-tab-pane> -->
      <a-tab-pane :key="2" tab="会员列表">
      </a-tab-pane>
    </a-tabs>

    <a-table
      :rowKey="(record,index) => record.indexId"
      :data-source="tableData"
      :loading='loading'
      :columns="columns"
      :pagination="pagination"
      @expand="getChild"
      @change='tableChange'>
      <template slot='state' slot-scope="text, record">
        <a-switch :checked='record.state' :loading='userMemberId===record.userMemberId' @change='changeState($event,record)' checked-children="正常" un-checked-children="冻结"></a-switch>
      </template>
      <div class="table-operations" slot="operation" slot-scope="text, record">
        <a-button type="link" @click="viewTeamSales(record)">团队业绩</a-button>
        <a-button type="link" @click='editRecord(record)'>修改等级</a-button>
        <a-popconfirm placement="topRight" :title="`确定要注销该用户吗？`" ok-text="确定" cancel-text="取消" @confirm="deleteUser(record)">
          <a-button type="link">用户注销</a-button>
        </a-popconfirm>
        <a-button type="link" @click='regisDown(record)'>注册下级</a-button>
<!--        <a-button type="link" @click='editUp(record)'>修改上级</a-button>-->
      </div>
    </a-table>
    <a-modal v-model="showSalesModal " title='团队业绩情况' @cancel='cancelSales'>
      <a-form-model  ref="modalFormSales" :model="form" :label-col="{span:12}" :wrapper-col="{span: 12}" width="840px">
        <a-row>
          <a-col :span='12'>
            <a-form-model-item label="当前等级">
              <div>{{teamData.lawLevelName}}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span='12'>
            <a-form-model-item label="会员等级">
              <div>{{teamData.levelName}}</div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span='12'>
            <a-form-model-item label="团队人数">
              <div>{{teamData.teamNum}}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span='12'>
            <a-form-model-item label="已购买人数">
              <div>{{teamData.buyPersonNum}}</div>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span='12'>
            <a-form-model-item label="个人业绩">
              <div>{{teamData.selfSaleVolume}}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span='12'>
            <a-form-model-item label="团队业绩">
              <div>{{teamData.teamSaleVolume}}</div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span='12'>
            <a-form-model-item label="个人新积分额度">
              <div>{{teamData.bonusAmount}}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span='12'>
            <a-form-model-item label="老积分额度">
              <div>{{teamData.bonusAmountOld}}</div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span='12'>
            <a-form-model-item label="团队新积分额度">
              <div>{{teamData.bonusAmountTeam}}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span='12'>
            <a-form-model-item label="团队老积分额度">
              <div>{{teamData.bonusAmountOldTeam}}</div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span='12'>
            <a-form-model-item label="个人积分">
              <div>{{teamData.accountAmount}}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span='12'>
            <a-form-model-item label="团队积分">
              <div>{{teamData.accountAmountTeam}}</div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span='12'>
            <a-form-model-item label="个人购物券">
              <div>{{teamData.beanAmount}}</div>
            </a-form-model-item>
          </a-col>
          <a-col :span='12'>
            <a-form-model-item label="团队购物券">
              <div>{{teamData.beanAmountTeam}}</div>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <a-modal v-model="showModal" title='修改等级' :confirm-loading="confirmLoading" @ok='submit' @cancel='cancel'>
      <a-form-model ref="modalFormRef" :model="form" :rules="rules" :label-col="{span:4}" :wrapper-col="{span: 20}">
        <a-form-model-item label="选择等级" prop="levelId">
          <a-select style="width: 100%" placeholder="请选择" v-model='form.levelId'>
            <a-select-option :disabled="currentRecord.lawLevelId === item.levelId" :value="item.levelId" :key='item.levelList + index' v-for='(item,index) in levelList'>
              {{item.levelName}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal v-model="showModal1" title='注册下级' :confirm-loading="confirmLoading" @ok='submit1' @cancel='cancel1'>
      <a-form-model ref="modalFormRef1" :model="form" :rules="rules" :label-col="{span:6}" :wrapper-col="{span: 18}">
        <a-form-model-item label="当前上级">
          <a-input v-model="currentRecord.phone" style='width:200px' allowClear placeholder="请输入" :disabled="true"/>
        </a-form-model-item>
        <a-form-model-item label="下级登录账号" prop="loginName">
          <a-input v-model="form.loginName" style='width:200px' allowClear placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="登录密码" prop="loginPwd">
          <a-input v-model="form.loginPwd" style='width:200px' allowClear placeholder="请输入" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
<!--    <a-modal v-model="showModal1" title='修改上级' :confirm-loading="confirmLoading" @ok='submit1' @cancel='cancel1'>-->
<!--      <a-form-model ref="modalFormRef1" :model="form" :rules="rules" :label-col="{span:6}" :wrapper-col="{span: 18}">-->
<!--        <a-form-model-item label="新上级手机号" prop="loginName">-->
<!--          <a-input v-model="form.loginName" style='width:200px' allowClear placeholder="请输入" />-->
<!--        </a-form-model-item>-->
<!--      </a-form-model>-->
<!--    </a-modal>-->
  </div>
</template>

<script>
import { agent } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
export default {
  name: 'PageTeamManagement',
  data: () => ({
    loading: false,
    queryRef: {
      type: 0,
      levelId: undefined,
      dateRange: [],
    },
    showModal: false,
    showModal1: false,
    showSalesModal: false,
    userMemberId: null,
    infoData: null,
    teamData: {
      lawLevelName: null,
      levelName: null,
      teamNum: 0,
      buyPersonNum: 0,
      repurchaseCount: 0,
      repurchaseAmount: 0,
      repurchaseNoCount: 0,
      repurchaseNoAmount: 0,
      totalCount: 0,
      totalAmount: 0,
    },
    pagination: {
      current: 1,
      size: 'small',
      pageSize: 10,
      total: 0,
    },
    rules: {
      levelId: [{ required: true, message: '请选择' }]
    },
    form: {},
    currentRecord: {},
    confirmLoading: false,
    tableData: [],
    levelList: [],
    usersByLevel: [],
    selectLoading: false,
    columns: [
      {
        title: '姓名',
        dataIndex: 'name',
      },
      {
        title: '手机号',
        dataIndex: 'phone',
      },
      {
        title: '消费商等级',
        dataIndex: 'levelName',
      },
      // {
      //   title: '会员等级',
      //   dataIndex: 'lawLevelName',
      // },
      {
        title: '会员编号',
        dataIndex: 'userNo',
      },
      {
        title: '血缘上级',
        dataIndex: 'upAgentName',
        scopedSlots: { customRender: 'upAgentName' },
      },
      {
        title: '累计收益',
        dataIndex: 'totalAmount',
      },
      {
        title: '可提现收益',
        dataIndex: 'cashAmount',
      },
      {
        title: '余额',
        dataIndex: 'balanceAmount',
      },
      {
        title: '加入时间',
        dataIndex: 'joinTime',
      },
      {
        title: '状态',
        dataIndex: 'state',
        scopedSlots: { customRender: 'state' },
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
      },
    ],
  }),
  mounted () {
    this.getLevelList()
    this.getInfo()
    this.getData()
  },
  methods: {
    changeTab (e) {
      this.queryRef.type = e
      this.pagination.current = 1
      this.getData()
    },
    async getInfo () {
      const { data, code } = await agent.getTeamInfoData()
      if (code === '00000') {
        this.infoData = data
      }
    },
    async getLevelList () {
      const { data, code } = await agent.getTeamLevelList({ relationType: 2 })
      if (code === '00000') {
        this.levelList = data
      }
    },
    async getData () {
      this.loading = true
      let joinBeginTime = this.queryRef.dateRange && this.queryRef.dateRange.length ? this.queryRef.dateRange[0] : ''
      let joinEndTime = this.queryRef.dateRange && this.queryRef.dateRange.length ? this.queryRef.dateRange[1] : ''
      delete this.queryRef.dateRange
      const { data, code, page, msg } = await agent.getTeamPage({
        ...this.queryRef,
        joinBeginTime: joinBeginTime,
        joinEndTime: joinEndTime,
        current: this.pagination.current,
        size: this.pagination.pageSize,
      })
      this.loading = false
      if (code === '00000') {
        this.tableData = data
        this.pagination.current = page.pageNo
        this.pagination.pageSize = page.pageSize
        this.pagination.total = page.total
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    async getChild (expanded, record) {
      if (!expanded) return
      if (record.children && record.children.length > 0) return
      this.loading = true
      delete this.queryRef.dateRange
      const { data, code, msg } = await agent.getChild({
        upUserId: record.userId
      })
      this.loading = false
      if (code === '00000') {
        const dataSourceMap = (items) => {
          items.find((item) => {
            if (item.indexId === record.indexId) {
              // 找到当前要展开的节点
              item.children = data
              return items
            }
            if (item.children && item.children.length > 0) {
              dataSourceMap(item.children)
            }
          })
        }
        dataSourceMap(this.tableData)
        this.tableData = [...this.tableData]
      } else {
        this.$message.error(msg || '获取数据失败')
      }
    },
    handleSearch () {
      this.pagination.current = 1
      this.getData()
    },
    handleReset () {
      this.$refs.formRef.resetFields()
      this.getData()
    },
    tableChange ({ current }) {
      this.pagination.current = current
      this.getData()
    },
    async viewTeamSales (record) {
      const { data, msg, code } = await agent.getTeamSales({ userId: record.userId })
      if (code === '00000') {
        this.teamData = data
      } else {
        this.$message.error(msg || '获取数据失败')
      }
      this.showSalesModal = true
    },
    editRecord (record) {
      this.currentRecord = Object.assign({}, record)
      this.showModal = true
    },
    editUp (record) {
      this.currentRecord = Object.assign({}, record)
      this.showModal1 = true
    },
    async deleteUser (record) {
      this.confirmLoading = true
      const { msg, code } = await agent.deleteUser({
        userId: record.userId,
        shopId: getSession(SHOPID),
      })
      this.confirmLoading = false
      if (code === '00000') {
        this.$message.success('注销成功')
        this.getData()
      } else {
        this.$message.error(msg || '操作失败')
      }
    },
    regisDown (record) {
      this.currentRecord = Object.assign({}, record)
      this.showModal1 = true
    },
    // async changeSelectLevel (e) {
    //   console.log(e)
    //   this.selectLoading = true
    //   let { data, msg, code } = await agent.usersByLevel({
    //     prelevelId: this.currentRecord.levelId,
    //     afterlevelId: e,
    //     userId: this.currentRecord.userId
    //   })
    //   this.selectLoading = false
    //   if (code === '00000') {
    //     if (!data || !data.length) {
    //       data = [{
    //         realName: '暂无可选上级',
    //         userId: ''
    //       }]
    //     }
    //     this.usersByLevel = data
    //   }
    // },
    async  submit () {
      this.confirmLoading = true
      const { code, msg } = await agent.changeUserLevel({
        userId: this.currentRecord.userId,
        shopId: getSession(SHOPID),
        lawLevelId: this.form.levelId,
        lawUserPid: this.currentRecord.userPid
      })
      this.confirmLoading = false
      if (code === '00000') {
        this.showModal = false
        this.handleSearch()
      } else {
        this.$message.error(msg || '操作失败')
      }
    },
    async submit1 () {
      if (!this.form.loginName && !this.form.loginPwd) {
        this.$message.error('请填写账号密码')
        return
      }
      this.confirmLoading = true
      const { code, msg } = await agent.regisDown({
        userId: this.currentRecord.userId,
        shopId: getSession(SHOPID),
        loginName: this.form.loginName,
        loginPwd: this.form.loginPwd,
      })
      this.confirmLoading = false
      if (code === '00000') {
        this.showModal1 = false
        this.handleSearch()
      } else {
        this.$message.error(msg || '操作失败')
      }
    },
    cancel1 () {
      this.showModal1 = false
      this.$refs.modalFormRef1.resetFields()
    },
    cancel () {
      this.showModal = false
      this.$refs.modalFormRef.resetFields()
    },
    cancelSales () {
      this.showSalesModal = false
      this.$refs.modalFormSales.resetFields()
    },
    async changeState (e, record) {
      this.userMemberId = record.userMemberId
      let state = 0
      if (e) {
        state = 1
      } else {
        state = 0
      }
      this.confirmLoading = true
      const { msg, code } = await agent.changeState({
        userMemberId: record.userMemberId,
        state,
      })
      this.confirmLoading = false
      this.userMemberId = ''
      if (code === '00000') {
        this.$message.success('操作成功')
        this.getData()
      } else {
        this.$message.error(msg || '操作失败')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.info-box {
  display: flex;
  align-items: center;
  margin: 12px;
  .info-item {
    margin-right: 48px;
  }
}
</style>
